import type { RouteResult } from '~/types'

export const formatTime = (seconds: number) => {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.round(seconds % 60)
  return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter(Boolean).join(':')
}

export const registerUrl = (): string => {
  return 'https://tools.zeno.fm/auth/realms/broadcasters/protocol/openid-connect/registrations?client_id=zeno-tools&redirect_uri=https%3A%2F%2Ftools.zeno.fm%2F&response_mode=fragment&response_type=code&scope=openid'
}

export const loginUrl = (): string => {
  return 'https://tools.zeno.fm/auth/realms/broadcasters/protocol/openid-connect/auth?client_id=zeno-tools&redirect_uri=https%3A%2F%2Ftools.zeno.fm%2F&response_mode=fragment&response_type=code&scope=openid'
}

export const appStoreUrl = (): string => {
  return 'https://apps.apple.com/us/app/zenoradio/id1207197757'
}

export const googlePlayUrl = (): string => {
  // return 'https://play.google.com/store/apps/details?id=com.zenofm.player&hl=en_ZA&pli=1'
  return 'https://zenoradio.onelink.me/q17U/wi68vzmj'
}

export const oneLinkUrl = (): string => {
  return 'https://zenoradio.onelink.me/q17U/qj8pl1em'
}

export const getStationRoute = (identifier: string): RouteResult => {
  return { name: 'radio-id', params: { id: identifier } }
}

export const getPodcastRoute = (identifier: string): RouteResult => {
  return { name: 'podcast-podcastId', params: { podcastId: identifier } }
}

export const getEpisodeRoute = (podcastId: string, episodeId: string) => {
  return { name: 'podcast-podcastId-episodes-episodeId', params: { podcastId: podcastId, episodeId: episodeId } }
}

export const isValidUrl = (url?: string) => {
  if (!url) {
    return false
  }

  try {
    return Boolean(new URL(url))
  } catch (e) {
    console.log(url + ' is not a valid URL', e)
    return false
  }
}
